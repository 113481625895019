/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.container {
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 5px
}

.example-spacer {
  flex: 1 1 auto;
}

.mt-10 {
  margin-top: 10px;
}
